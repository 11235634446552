.portfolio p {
    font-size: 1.25rem;
}

.portfolio .portfolio-links {
    /* display: flex; */
    /* justify-content: center; */
    gap: 2rem;
    margin: 3rem 0;
}
.portfolio .portfolio-link {
    min-width: 20rem;
    max-width: 100%;
    display: flex;
    width: fit-content;
    justify-content: space-between;
    text-decoration: none;
    border: 3px solid var(--kasha-bg);
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    text-align: center;
}
.portfolio .portfolio-link .size {
    opacity: .4;
}
.portfolio .portfolio-link:hover, .portfolio .portfolio-link:focus-within {
    border-color: var(--kasha-primary);
}