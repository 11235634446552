.footer {
    margin: 0 0 2rem;
}
.footer h1 {
    font-family: Nesans;
    color: var(--kasha-primary);
    /* text-align: center; */
    font-size: clamp(2rem, 12vw, 4rem);
    margin: 1rem 0;
    font-weight: 400;
}
.footer .columns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer .left, .footer .right {
    width: 50%;
}
.footer .title {
    font-size: 1.2rem;
    font-weight: 500;
}
.footer .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: .5rem;
}
.footer .item {
    padding: .5rem .5rem;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    font-size: 1.25rem;
    outline: none;
}
.footer .item:focus-within {
    border-color: var(--kasha-primary);
}
.footer .item:hover {
    border-color: var(--kasha-bg);
}
.footer-pattern {
    height: 10rem;
    background-color: var(--kasha-secondary);
    
    background-position: calc(50% + 8px) 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='32' height='32' fill='%2326479D'/%3E%3C/svg%3E");
}
  

@media screen and (max-width: 800px) {
    .footer .columns {
        flex-wrap: wrap;
    }
    .footer .left, .footer .right {
        width: 100%;
    }
    .footer h1 {
        text-align: center;
    }
    .footer .right {
        text-align: unset;
        display: flex;
        justify-content: center;
    }
}