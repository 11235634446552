.btt-btn {
    position: sticky;
    z-index: 5;
    bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background-color: var(--kasha-bg);
    cursor: pointer;
    margin: 1rem 0 1rem auto;
    opacity: .7;
    border: 3px solid transparent;
    line-height: 1;
}
.btt-btn span {
    font-weight: 900;
    font-size: 1.5rem;
    color: var(--kasha-primary);
}
.btt-btn:before {
    content: attr(data-tooltip);
    pointer-events: none;
    touch-action: none;
    position: absolute;
    top: 50%;
    right: 125%;
    opacity: 0;
    transform: translateY(-50%);
    white-space: nowrap;
    padding: .25rem .5rem;
    border-radius: .25rem;
    background-color: var(--kasha-bg);
}
.btt-btn:hover, .btt-btn:focus-within {
    opacity: 1;
    border-color: var(--kasha-primary);
}
.btt-btn:hover:before, .btt-btn:focus-within:before {
    opacity: 1;
}
@media screen and (max-width: 800px) {
    .btt-btn {
        opacity: 1;
    }    
}
