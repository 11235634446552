.how-container {
    display: flex;
    /* min-height: 25rem; */
    flex-direction: row-reverse;
    align-items: flex-start;
}
.how-accordion {
    width: 40%;
}
.how-media {
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: center;
    transition: opacity .25s ease-in-out;
    opacity: 0;
}
.how-media.fade-in {
    opacity: 1;
}
.how-media .how-image {
    width: 50%;
    height: auto;
    aspect-ratio: 1;
}
.how-accordion {
    counter-reset: list-number;
}
.how-item {
    counter-increment: list-number;
    border-bottom: 2px solid black;
}
.how-item:first-of-type {
    border-top: 2px solid black;
}
.how-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem .5rem;
}
.how-item.collapsed .how-title-container {
    cursor: pointer;
}
.how-title:before {
    content: counter(list-number) '. ';
    opacity: .5;
    font-weight: 600;
    width: 2rem;
    display: inline-block;
}
.how-title {
    font-size: 1.5rem;
    font-weight: 600;
    transition: all .5s ease-in-out;
}
.how-item.collapsed .how-title {
    font-weight: 400;
}
.how-item .how-arrow {
    width: 2.5rem;
    height: 2.5rem;
    transform: rotate(-90deg);
    transition: transform .25s ease-in-out;
    opacity: 1;
    border-radius: 100%;
}
.how-item.collapsed .how-title-container:hover .how-arrow {
    background-color: var(--kasha-bg);
}
.how-item.collapsed .how-arrow {
    transform: rotate(0);
}
.how-body {
    max-height: 30rem;
    transition: max-height .3s ease-in-out;
    overflow: hidden;
    margin-left: 2.5rem;
    margin-right: 3rem;
}
.how-body .how-media {
    display: none;
}
.how-body p {
    margin: .5rem 0 1.5rem;
}
.how-item.collapsed .how-body {
    max-height: 0;
}
@media screen and (max-width: 800px) {
    .how-container {
        flex-wrap: wrap;
        height: unset;
    }
    .how-container > * {
        width: 100%;
    }
    .how-item .how-arrow {
        transform: rotate(-180deg);
    }
    .how-body {
        max-height: 50rem;
        margin: 0 .25rem;
    }
    .how-media {
        display: none;
    }
    .how-body .how-media {
        display: flex;
        width: 100%;
    }
}