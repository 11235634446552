.navbar {
    position: sticky;
    top: 0;
    z-index: 10;
    /* border-bottom: 1px solid var(--kasha-bg); */
    background-color: rgba(239, 235, 228, .90);
}
.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
}
.navbar-container a {
    text-decoration: none;
}
.navbar-brand {
    font-family: Nesans;
    color: var(--kasha-primary);
    font-size: 2.5rem;
    padding: .25rem 0;
}
.navbar-brand a {
    display: flex;
    align-items: center;
    outline: none;
}
.navbar-brand img {
    width: 3.75rem;
    height: 3.75rem;
    padding: 3px;
    border-radius: 100%;
    background-color: transparent;
    transform: translateX(-3px);
}
.navbar-brand:hover img, .navbar-brand:focus-within img {
    background-color: var(--kasha-primary);
}
.navbar-links {
    display: flex;
    gap: .5rem;
}
.navbar-links a {
    padding: .5rem;
    font-size: 1.25rem;
    border-bottom: 3px solid transparent;
    font-weight: 500;
    outline: none;
}
.navbar-links a:hover, .navbar-links a:focus-within {
    border-color: var(--kasha-primary);
}
.navbar-links a.active {
    color: var(--kasha-primary);
    font-weight: 600;
}

@media screen and (max-width: 800px) {
    .navbar-brand {
        font-size: 2rem;
    }
}