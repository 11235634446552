:root {
    --kasha-primary: rgb(38, 71, 157); /* #26479D */
    --kasha-secondary: rgb(239, 235, 228); /* #EFEBE4 */
    --kasha-bg: rgb(217, 213, 202); /* #D9D5CA */
    --max-width: 1200px;
}
* {
    box-sizing: border-box;
}

@font-face {
    font-family: "Nesans";
    src: url("../assets/fonts/nesans.woff2");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../assets/fonts/Satoshi-Variable.woff2') format('woff2');
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../assets/fonts/Satoshi-VariableItalic.woff2') format('woff2');
    font-weight: 300 900;
    font-display: swap;
    font-style: italic;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--kasha-secondary);
    font-family: Satoshi;
}
button {
    font-family: Satoshi;
}
h1, h2, h3, h4, h5 {
    margin: .5em 0;
}
a, a:visited, a:hover, a:active {
    color: inherit;
}
.container {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 1rem;
}
.main-content {
    position: relative;
    min-height: 65vh;
    padding: .5rem 0;
}
.container, .main-content {
    transition: .3s all ease-in-out;
}
.main-content h1 {
    margin-top: 0;
    font-size: 6rem;
    font-weight: 400;
    font-family: Nesans;
}
.kasha-primary {
    color: var(--kasha-primary);
}
.dot-photo-container {
    position: relative;
    width: 100%;
}
.dot-photo-container.show-placeholder {
    background-color: var(--kasha-bg);
}
.dot-photo {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all .3s ease-in-out;
    clip-path: circle(0% at 16px 16px);
    background-color: var(--kasha-secondary);
    outline: none;
}
.dot-photo-container:not(.show-placeholder) .dot-photo {
    clip-path: unset;
}
.dot-photo-container.loaded .dot-photo {
    clip-path: circle(150% at 16px 16px);
}
.dots {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(var(--kasha-primary) 3px, transparent 3px);
    background-size: 32px 32px;
    clip-path: circle(150% at 16px 16px);
    z-index: 1;
    transition: all .3s ease-in-out;
}
.dot-photo-container.loaded .dots {
    clip-path: circle(0% at 16px 16px);
}

@media screen and (max-width: 800px) {
    .container {
        padding: 0 .5rem;
    }
    .main-content h1 {
        font-size: 4rem;
        margin-bottom: 2rem;
    }
}