.home .hero .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 72px);
}
.home .hero h1 {
    font-family: Nesans;
    font-size: clamp(2rem, 16vw, 6rem);
    font-weight: 400;
    margin: .5rem 0;
}

.home .hero-img {
    max-width: 100%;
    width: auto;
    height: 75vh;
    aspect-ratio: 1.5;
}
.home .hero-actions {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 0 0 2rem;
}
.home .hero-action {
    text-decoration: none;
    border-bottom: 3px solid var(--kasha-bg);
    padding: .25rem;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    text-align: center;
}
.home .hero-action:hover, .home .hero-action:focus-within {
    border-color: var(--kasha-primary);
}

.home h2 {
    font-size: 3rem;
    margin: 3rem 0 1rem;
}
.home p {
    font-size: 1.25rem;
}
.home p:first-child:not(:last-child) {
    margin-top: .5rem;
}
.home p:last-child:not(:first-child) {
    margin-bottom: .5rem;
}

.home .tiles-container {
    display: flex;
    gap: .5rem;
}
.home .tile {
    height: auto;
    width: 25%;
    aspect-ratio: 1;
}
.home .follow-tile {
    display: block;
    text-decoration: none;
    background: var(--kasha-primary);
    padding: 6px;
}
.home .follow-tile:hover, .home .follow-tile:focus-within {
    outline: none;
    background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7);
}
.home .follow-tile-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    background-color: var(--kasha-secondary);
}
.home .follow-tile .logo {
    width: 5rem;
}
.home .follow-tile .handle {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: .5rem 1rem;
    border: 4px solid var(--kasha-primary);
    color: var(--kasha-primary);
    border-radius: 2rem;
}

@media screen and (max-width: 800px) {
    .home .hero .container h1 {
        order: 1;
        margin-bottom: 3rem;
    }
    .home h2 {
        font-size: 2rem;
        margin: 2rem 0 0;
    }
    .home .hero .container .hero-img {
        order: 2;
        height: auto;
        width: 100%;
    }
    .home .hero .container .hero-actions {
        order: 3;
        margin: 4rem 0;
    }
    .home .hero-action {
        padding: .5rem;
    }
    .home .hero .container {
        flex-wrap: wrap;
    }
    .home .tiles-container {
        flex-wrap: wrap;
        gap: .5rem .25rem;
        justify-content: space-around;
    }
    .home .tile {
        /* margin: .25rem; */
        width: calc(50% - .5rem);
    }
    .home .follow-tile {
        padding: 4px;
    }
    .home .follow-tile .logo {
        width: 3.5rem;
    }
    .home .follow-tile .handle {
        font-size: 1rem;
        border-width: 2px;
        padding: .25rem .5rem;
    }
}